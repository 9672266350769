body {
  background-color: #1D1D1D;
  color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  font-family: 'Open Sans', sans-serif;
}

#main {
  width: calc(100vw - 150px)
}

@media (max-width: 1000px) {
  #main {
    width: 100vw;
  }
}

#menu-logo {
  flex: 2;
  width: 150px;
}

#menu-content {
  flex: 5;
  width: 100%;
}

#menu-footer {
  flex: 1;
  width: 150px
}

#menu-footer-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#menu-footer-linkedin-icon {
  opacity: 0.13
}

#menu-footer-linkedin-icon:hover {
  opacity: 0.5
}

#menu-footer-github-icon {
  opacity: 0.14
}

#menu-footer-github-icon:hover {
  opacity: 0.5
}

#menu-button {
  position: absolute;
  opacity: 0.8;
  left: calc(100vw - 60px - 14px);
  top: 14px;
  width: 60px;
  height: 60px;
  background-color: #000000;
  z-index: 99;
  cursor: pointer;
}

.menuItemButton {
  position: relative;
  color: #616165;
  font-weight: 400;
  font-size: 18px;
  background-color: #1D1D1D;
  border-style: none none solid solid;
  border-width: 1px;
  border-color: #616165;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
  z-index: 101;
}

.menuItemButton:hover {
  color: #1D1D1D;
  background-color: #616165;
  cursor: pointer;
  /* transition: 0.3s; */
}

.menuItemButtonWithNoPadding {
  color: #616165;
  font-weight: 400;
  font-size: 18px;
  background-color: #1D1D1D;
  border-style: none none solid solid;
  border-width: 1px;
  border-color: #616165;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.menuItemButtonWithNoPadding:hover {
  color: #1D1D1D;
  background-color: #616165;
  /* transition: 0.3s; */
}

.menuItemButtonLink {
  display:block;
  color: #616165;
  font-weight: 400;
  font-size: 18px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.menuItemButtonLink:hover {
  color: #1D1D1D;
  background-color: #616165;
  /* transition: 0.3s; */
}

/*          */
/*   Logo   */
/*          */

#logo-container {
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  height: 140px;
  background-color: #000000;
  padding: 10px 0px 30px 0px;
}

#logo-box {
  font-size: 100px;
  font-weight: 600;
  height: 130px;
}

#logo-s {
  position: relative;
  left: 30px;
  top: -20px;
  color: #6f70c4;
  width: 55px;
}

#logo-o {
  position: relative;
  left: 45px;
  top: -130px;
  width: 80px;
  color: #ECC05B;
}

#logo-home-page {
  color: #616165;
  text-align: center;
}

/*                 */
/*   Welcome Page  */
/*                 */

#WelcomePageContainer {

  background-color: #1D1D1D;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow:unset;
  scrollbar-width: none;
  display: flex;
  position:relative;
}

#welcome-page-svg-background {
  width: 100%;
  position: absolute;
  height: 100vh;
  z-index: 0;
}

#welcome-page-svg-background svg {
  width: 100%;
  height: 100%;
}

#WelcomeBox {

  width: 90%;
  color: #FFFFFF;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

@media (max-width: 800px) {
  #WelcomeBox {
    margin-left: 17px;
    margin-right: 40px;
  }
}

#welcomeBoxHeaderBox {
  margin: 20px;
  font-size: 60px;
  font-family: 'Open Sans', sans-serif;
  font-weight:800
}

@media (max-width: 1000px) {
  #welcomeBoxHeaderBox {
    font-size: 45px;
  }
}

@media (max-width: 800px) {
  #welcomeBoxHeaderBox {
    margin: 0px 0px 0px 12px;
    font-size: 30px;
  }
}

#welcomeBoxHeaderBoxLetterS {
  color:#6f70c4;
  font-size: 70px;
}

@media (max-width: 1000px) {
  #welcomeBoxHeaderBoxLetterS {
    font-size: 60px;
  }
}

@media (max-width: 800px) {
  #welcomeBoxHeaderBoxLetterS {
    font-size: 50px;
  }
}

#welcomeBoxHeaderBoxLetterO {
  color:#ECC05B;
  font-size: 70px;
}

@media (max-width: 1000px) {
  #welcomeBoxHeaderBoxLetterO {
    font-size: 60px;
  }
}

@media (max-width: 800px) {
  #welcomeBoxHeaderBoxLetterO {
    font-size: 50px;
  }
}

#welcomeBoxPortfolioButtonsBox {
  /* border: red 1px solid; */
  margin-top: 8px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

@media (max-width: 800px) {
  #welcomeBoxPortfolioButtonsBox {
    margin-top: 25px;
    flex-direction: column;
    gap: 0px;
  }
}

.PortfolioButton {
  color: #ECC05B;
  font-size: 30px;
  font-weight: 700;
  border-color: #ECC05B;
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  margin: 10px;
  width: 280px;
  text-align: center;
}

.PortfolioButton:hover {
  background-color: #ECC05B;
  color: #1D1D1D;
  transition: 0.3s;
}

#welcomeBoxContactButtonBox {
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

#ContactMeButton {
  color: #6f70c4;
  font-size: 30px;
  font-weight: 700;
  border-color: #6f70c4;
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  margin: 10px;
  width: 280px;
  text-align: center;
  transition: 0.3s;
}

#ContactMeButton:hover {
  background-color: #6f70c4;
  color: #1D1D1D;
}

#web-dev-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
}


/*                */
/*   WebDev Page  */
/*                */

/* WebDevAboutMe */

#web-dev-about-me {
  position: relative;
}

#web-dev-about-me-container {
  /* position: relative; */
  background-color: #1D1D1D;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  /* padding: 20px; */
}

#dev-about-me-svg-background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

@media (max-width: 1000px) {
  #web-dev-about-me-container {
    flex-direction: column;
  }
}

#web-dev-about-me-content {
  flex: 1;
  padding: 20px;
  position: relative;
  z-index: 2;
  /* border-style: solid;
  border-width: 1px; */
}

#web-dev-about-me-content-text {
  position: relative;
  height: 80%;
}

#web-dev-about-me-photo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-style: solid;
  border-width: 1px; */
}

#web-dev-about-me-photo img {
  position: relative;
  max-width:100%;
  max-height:500px;
  border-radius: 50%;
  z-index: 2;
}

/* Education */

#web-dev-education{
  position: relative;
}

#web-dev-education-background{
  position: absolute;
  top: 40px
}

#nc-image {
  width: 50%;
  opacity: 0.8;
}
#nc-logo-image {
  width: 50%;
  opacity: 0.7;
}

@media (max-width: 800px) {
  #nc-logo-image {
    width: 100%;
    opacity: 0.5;
  }
}

#web-dev-education-container {
  /* border: green 1px solid; */
  position: relative;
  background-color: transparent;
  margin: 0px;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  padding: 20px
}


#web-dev-education-container h1 {
  margin: 10px 0px 30px 0px
}

#web-dev-education-northcoders {
  /* border: green 1px solid; */
  height: 63%;
  max-width: 800px
}

#web-dev-education-title {
  font-size: 1.5em;
  margin-bottom: 20px;
}

#web-dev-education-description {

}

/* Projects */

#web-dev-projects-container {
  /* border: green solid 1px; */
  margin: 0px;
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  /* height: calc(100vh - 40px); */
  padding: 20px 20px 40px 30px
}

#web-dev-projects-container h1 {
  margin: 10px 0px 30px 0px
}

#web-dev-projects-all {
  /* border: red solid 1px; */
  margin-right: 20px;
}

#project-card-container {
  height: 40%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 800px) {
  #project-card-container {
    flex-direction: column;
  }
}

#project-card-image-div {
  overflow:hidden;
  flex: 1;
  border: solid 1px #616165;
}

#project-card-image {
  width: 100%;
}

#project-card-description {
  border-color: #616165;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  flex: 2;
  padding: 20px;
  padding: 20px 20px 20px 0px
}

#project-name {
  /* border: 1px solid green; */
  width: 100%;
  background-color: #333333;
  margin: 10px 0px 20px 0px;
  padding: 2px 0px 4px 20px;
  font-size: 1.6em;
  font-weight: 800;
}

#project-subheading {
  padding: 0px 0px 0px 20px;
  margin: 5px 0px 20px 0px;
  
}

#project-description {
  padding: 0px 0px 0px 20px;
}


#project-links {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

#project-run-app-button {
  cursor: pointer;
  background-color: #3d3d3d;
  border-radius: 15px;
  text-align: center;
  color: #1D1D1D;
  font-size: 2.5em;
  font-weight: 800;
  line-height: 70px;
  height: 70px;
  width: 100px;
}

#project-run-app-button:hover {
  cursor: pointer;
  background-color: #8e8e8e;
}

#project-repo {
  margin: 0px 0px 0px 20px;
  /* border: #ECC05B 1px solid; */
}


/* Skills */

#web-dev-skills-container {
  /* border: red 1px solid; */
  margin: 0px;
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  padding: 20px 20px 40px 30px
}

#web-dev-skills-container h1 {
  margin: 10px 0px 30px 0px
}

#web-dev-skills-main {
  font-size: 1.2em;
  /* border: 1px solid red; */
  height: 85%;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  padding-right: 10px;
}

#web-dev-skills-main ul {
  list-style-type: none;
  padding: 0px;
  margin: 15px 5px 5px 20px;
}

#web-dev-skills-main li {
border-width: 0px 0px 1px 1px;
border-color: #616165;
border-style: solid;
margin: 8px 2px 2px 2px;
padding: 3px 3px 3px 10px;
}

@media (max-width: 800px) {
  #web-dev-skills-main {
    flex-direction: column;
  }
}

#web-dev-skills-left {
  /* border: 1px solid green; */
flex: 1
}

#web-dev-skills-center {
  /* border: 1px solid green; */
  width: 96%;
  flex: 3
}

#web-dev-skills-right {
  /* border: 1px solid green; */
  flex: 1
}

#web-dev-skills-top {
  display: flex
}

#web-dev-skills-top div {
  flex: 1
}

#skills-sphere-container{
  /* border: 1px solid green; */
  height: 100%;
}

/* WebDevContact */

#web-dev-contact-container {
  /* border: blue 1px solid; */
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  min-height: calc(100vh - 40px);
  padding: 20px 20px 40px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 800px) {
  #web-dev-contact-container {
    flex-direction: column;
  }
}

#web-dev-contact-container div {
  /* border: red solid 1px; */
  flex:1
}

#web-dev-contact-form-container {
  width: 100%;
  height: 100%
}

#web-dev-contact-image-container {
  width: 100%;
}

#web-dev-contact-image-container img{
  width: 100%;
}

#contact-form label {
  border-color: #616165;
  border-style: solid;
  border-width: 0px 0px 1px 1px;
  padding: 10px;
}

#contact-form input[type=text] {
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 6px;
  width: calc(98% - 15px);
  background-color: #1D1D1D;
  color: #FFFFFF;
  border-color: #616165;
  border-style: solid;
  border-width: 1px 0px 1px 1px;
}

#contact-form input[type=email] {
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 6px;
  width: calc(98% - 15px);
  background-color: #1D1D1D;
  color: #FFFFFF;
  border-color: #616165;
  border-style: solid;
  border-width: 1px 0px 1px 1px;
}

#contact-form textarea {
  resize: none;
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 6px;
  width: calc(98% - 15px);
  background-color: #1D1D1D;
  color: #FFFFFF;
  border-color: #616165;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
}

#contact-send-button {
  width: 100%;
  background-color: #222222;
  padding: 5px 20px 5px 20px;
  color:#FFFFFF;
  align-self: 'right';
  margin-top: 20px;
  margin-left: auto;
  font-size: 1.2em;
  border-color: #616165;
  border-style: solid;
  border-width: 1px 1px 2px 2px;
}

/*                */
/*   Music Page   */
/*                */

#music-all-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

/*   MusicAboutMe   */

#music-about-me {

  background-color: #1D1D1D;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

#music-about-me-svg-background {
  position: absolute;
  height: 100vh;
  z-index: 0;
}

#music-about-me-svg-background svg {
  width: 100%;
  height: 100%;
}


#music-about-me-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 1200px) {
  #music-about-me-container {
    flex-direction: column;
  }
}

#music-about-me-text {
  padding: 20px 20px 40px 30px;
  flex: 1;
  z-index: 1;
  /* border: yellow solid 1px */
}

#music-about-me-photo-container {
  flex: 1;
  /* border: white solid 1px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/*   MyWorks   */

#my-works {

  background-color: #1D1D1D;
  width: calc(100% - 40px);
  min-height: calc(100vh - 40px);
  padding: 20px 20px 40px 30px
}

#my-works-videocards-container {
  margin-left: auto;
  margin-right: 20px;
  max-width: 1620px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#pagination-box {
  text-align: center;
  margin-top: 8px;
  margin-right: 15px;
}

#pagination-box button {
margin: 2px;
padding: 6px 10px 6px 10px;
min-width: 25px;
}

.pagination-button-active {
  cursor: pointer;
  background-color: #616165;
  color: #000000;
  border-color: #000000;
  border-style:solid;
  border-radius: 0%;
  border-width: 1px;
}

.pagination-button-inactive {
  cursor: pointer;
  background-color: #000000;
  color: #616165;
  border-color: #616165;
  border-style:solid;
  border-radius: 0%;
  border-width: 1px;
}

.pagination-button-prev {
  cursor: pointer;
  background-color: #000000;
  color: #616165;
  border-color: #616165;
  border-style:solid;
  border-radius: 0%;
  border-width: 1px;
}

.pagination-button-prev-disabled {
  background-color: #1D1D1D;
  color: #282828;
  border-color: #282828;
  border-style:solid;
  border-radius: 0%;
  border-width: 1px;
}

.pagination-button-next {
  cursor: pointer;
  background-color: #000000;
  color: #616165;
  border-color: #616165;
  border-style:solid;
  border-radius: 0%;
  border-width: 1px;
}

.pagination-button-next-disabled {
  background-color: #1D1D1D;
  color: #282828;
  border-color: #282828;
  border-style:solid;
  border-radius: 0%;
  border-width: 1px;
}

.myworks-videos .video-card {
  width: 408px;
  height: 285px;
}

.myworks-videos .video-card-player {
  border: none;
  width: 100%;
  height: 170px;
}

.myworks-videos .video-card-description {
  background-color: #222222;
  width: 100%;
  text-align: center;
  padding-bottom: 6px
}

/*   MyMusic   */

#my-music {
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  padding: 20px 20px 40px 30px
}

/*   MyDiscography   */

#my-discography {
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  min-height: calc(100vh - 40px);
  padding: 20px 20px 40px 20px;
}

#my-discography-album-cards-container {
  /* border: 1px red solid; */
  width: 100%;
  /* height: calc(100% - 97px); */
  display: flex;
  flex-wrap: wrap;
  /* overflow: -moz-scrollbars-vertical; 
  overflow-y: scroll; */
  justify-content: center;
}

.my-discography-album-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
}

.my-discography-album-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
}

.my-discography-album-card:hover .my-discography-album-card-inner {
  transform: rotateY(180deg);
  transition: 0.3s;
}

.my-discography-album-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.my-discography-album-card-back {

  transform: rotateY(180deg);
  background-color: #1D1D1D;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-discography-album-card-artist {
  font-size: 1.5em;
}

.my-discography-album-card-title {
  font-size: 1.2em;
  margin-bottom: 12px;
}

.my-discography-album-card-released {
  font-size: 0.9em;
  color: #616165;
}

.my-discography-album-card-released2 {
  color: #717175;
}

.my-discography-album-card-myRole {
  margin-top: 10px;
  margin-bottom: 5px;
}

.my-discography-album-card-listen-container {
  margin: 10px 0px 0px 0px;
  /* border: red 1px solid; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-discography-album-card-listen {
  height: min-content;
  margin-right: 15px;
  /* border: red 1px solid; */
  color: #AAAAAA;
  font-size: 0.9em;
}


.my-discography-album-card-spotify {
  height: min-content;
  /* border: red 1px solid; */
  margin: 10px 0px 0px 0px;
  cursor: pointer;
  filter: grayscale(1)
}

.my-discography-album-card-spotify:hover {
  filter: grayscale(0)
}

/*   MusicForMarketing   */

#music-for-marketing {
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  padding: 20px 20px 40px 30px
}

#music-for-marketing-videos-container {
  height: 80%;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: hidden;
  scrollbar-width: none;
}


.marketing-videos {
  width: 50%;
}


.marketing-videos .video-card {
  height: 100%;
}

@media (max-width: 800px) {
  .marketing-videos {
    width: 100%;
  }
  .marketing-videos .video-card {
    height: 30vh;
  }
}

.marketing-videos .video-card-player {
  height: 100%;
  width: 100%;
  border:none;
}

/*   FilmScoring   */

#film-scoring {
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  padding: 20px 20px 40px 30px
}

#filmscoring-videos-container {
  height: 80%;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.filmscoring-videos {
  width: 100%;
}

.filmscoring-videos .video-card {
  height: 100%;
}

@media (max-width: 800px) {
  .filmscoring-videos .video-card {
    height: 60%;
  }
}

.filmscoring-videos .video-card-player {
  height: 100%;
  width: 100%;
  border:none;
}

/*   SoundDesign   */

#sound-design {
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  padding: 20px 20px 40px 30px
}

#sounddesign-videos-container {
  height: 80%;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.sounddesign-videos {
  width: 50%;
}

.sounddesign-videos .video-card {
  height: 100%;
}

@media (max-width: 800px) {
  .sounddesign-videos .video-card {
    height: 60%;
  }
}

.sounddesign-videos .video-card-player {
  height: 100%;
  width: 100%;
  border:none;
}

/*   MusicContact   */

#music-contact {
  background-color: #1D1D1D;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  padding: 20px 20px 40px 30px
}

#photo-div {
  width: 100%;
}

#photo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

@media (max-width: 1200px) {
  #photo {
    width: 100%;
    min-height: 35vh;
    max-height: 50vh;
  }
}

/*                  */
/*   Audio Player   */
/*                  */

.audio-player-full {
  /* border: solid 1px tomato; */
  margin-top: 30px;
  margin-right: 18px;
  height: calc(100vh - 190px)
}

.audio-player-top-bar {
  /* border: solid 1px yellow; */
  height: 90px;
  display: flex
}

.audio-player-currentsonginfo {
    /* border: solid 1px yellow; */
    width: calc(100% - 90px);
    padding-left: 12px;
    font-size: 1.3em;
}

.audio-player-track-nav-bar {
  /* border: solid 1px green; */
  margin-top: 8px;
  padding-top: 4px;
  height: 30px;
  display: flex;
  justify-content:space-between
}

.audio-player-track-nav-button-container {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content:space-evenly;
}

.audio-player-navbutton {
  margin: 2px
}

.audio-player-progressbar-container {
  width: calc(100% - 240px)
}

.audio-player-progressbar {
  width: 100%
}

.audio-player-time {
  margin-left: 12px;
  margin-right: 6px;
}

.audio-player-volume{
  margin-left: 5px;
  margin-right: 8px;
}

.audio-player-track-list {
  margin-top: 4px;
  border: solid 1px #333333;
  overflow: hidden;
  scrollbar-width: none;
  /* height: calc((100% - 125px) * 0.6); */
  height: calc((100% - 125px) * 1);
}

.audio-player-track {
  margin-top: 2px;
  padding: 4px 15px 4px 10px;
  border-color: #616165;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  display: flex;
  justify-content: space-between;
}

.audio-player-track:hover {
  background-color: #ECC05B;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
}



.audio-player-audio-visualiser {
  /* border: solid 1px green; */
  height: calc((100% - 125px) * 0.4);
  display: flex
}

/* 
........... 
*/

#content {
  width: 100%;
  height: 100%;
}

#thefile {
  background-color: #616165;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 100;
}

#canvas {
  width: 100%;
  height: 100%;
}

audio {
  left: 10px;
  bottom: 10px;
  width: calc(100% - 20px);
}

#Contact-Page {
  padding: 20px;
}